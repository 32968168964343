import React from "react";
import imgM from "../../assets/image/golf-hand.png";

const Content1 = ({ className, ...rest }) => {
  return (
    <div className={className} {...rest}>
      <div className="container">
        <div className="row justify-content-center justify-content-lg-start" style={{alignItems:'end'}}>
          {/* Image Section */}
          <div className="col-xl-6 col-xs-6 col-xs-12 order-md-1 order-xs-2">
            <div>
              <div className="img-2">
                <img
                  className="w-100"
                  src={imgM}
                  alt="Digma Interactive Real Estate"
                  data-aos="fade-right"
                  data-aos-delay={500}
                />

              </div>
            </div>
          </div>
          {/* Content Section */}
          <div className="col-xl-6 col-xs-6 col-xs-12 order-md-2 order-xs-1">
            <div className="pt-7 pl-sm-5 pl-md-9 pl-lg-10">
              <h2
                className="font-size-10 mb-9 Forms"
                data-aos="fade-up"
                data-aos-delay={300}
              >
                Give your guests the 5* golfing experience
              </h2>
              <p
                className="font-size-7 mb-0 pr-xl-13"
                data-aos="fade-up"
                data-aos-delay={600}
              >Via QR codes, your golfers can access your drone flyovers while on the course using their mobile devices.
              </p>
              <p className="font-size-7 mt-7 pr-xl-13" data-aos="fade-up"
                data-aos-delay={600}>The QR code will be linked to a secure and mobile friendly webpage, branded with your logo and colors. The webpage will host all 18-hole flyovers, making it easy for your golfers to follow along throughout their round. 
              </p>
              <div className="mb-9 mb-lg-12">
                <div class="digma-image" style={{maxWidth:'150px'}} data-imageid="AE932090-9890-467E-9790-EC56C10DF6D5"><img alt="get a quote" src="https://digmacustomers.blob.core.windows.net/4fe433c9-fb0f-47d4-9f59-37f1909ec6b8/b98f3d63-ab6e-487e-8bd7-9ab50b94c45d.png" /></div>
              </div>

                           
            </div>

          </div>
        </div>

      </div>
    </div>
  );
};

export default Content1;
