import React, { useState } from 'react';
import { Link } from 'gatsby';
import PageWrapper from '../../components/PageWrapper';
import Hero from '../../sections/golf/Hero';
import Wave from '../../sections/common/Wave';
import qr from '../../assets/qr-images/golf.png';

import Content1 from '../../sections/golf/Content1';
import Pricing from '../../sections/home4/Pricing';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import Commit from '../../sections/common/Commit';
import SEO from '../../components/SEO';
import imgC from '../../assets/image/metaimg/default.jpg';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';

const PricingPage = () => {
  const [showScrolling, setShowScrolling] = useState(false);
  const [showReveal, setShowReveal] = useState(false);

  useScrollPosition(({ prevPos, currPos }) => {
    if (currPos.y < 0) {
      setShowScrolling(true);
    } else {
      setShowScrolling(false);
    }
    if (prevPos.y > currPos.y) {
      setShowReveal(false);
    } else {
      setShowReveal(true);
    }
  });
  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: 'site-header--menu-left',
          headerFluid: false,
          footerStyle: 'digma',
          version: 'golf',
        }}
      >
        <SEO
          title="Golf course Flyovers"
          description="Via QR codes, your golfers can access your drone flyovers while on the course using their mobile devices."
          image={imgC}
        />
        <div className="container pt-20 pt-md-16 pt-lg-20 ">
          <nav className="breadcrumb" aria-label="Breadcrumb">
            <ol className="breadcrumb__list">
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/">
                  Home
                </Link>
                <span className="breadcrumb__separator" aria-hidden="true">
                  {' '}
                  /{' '}
                </span>
              </li>
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/industries">
                  Industries
                </Link>
                <span className="breadcrumb__separator" aria-hidden="true">
                  {' '}
                  /{' '}
                </span>
              </li>
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/industries/golf">
                  Golf
                </Link>
              </li>
            </ol>
          </nav>
        </div>
        <Hero />
        <Wave color="#F7F9FC" />
        <Content1 className="grey pt-10 pt-md-20 pt-lg-15 overflow-hidden position-relative" />
        <div className="container mb-md-20 mt-md-20 mb-0 mt-0">
          <div className="row justify-content-center">
            <div className="col-md-4 col-xs-12">
              <div className="mt-mb-20 mt-10">
                <h3 className="Forms">Qr Codes</h3>
                <p className="font-size-7 pr-xl-13">
                  Digma will design and provide you with your QR code to apply
                  as stickers on carts, printed on scorecards, etc.
                </p>
              </div>
            </div>
            <div className="col-md-8 col-xs-12">
              <img src={qr} className="w-100 p-0 mt-10 mb-10" alt="" />
            </div>
          </div>
        </div>
        <div className="container mb-md-20 mt-md-20 mb-0 mt-0">
          <div className="row justify-content-center">
            <div className="col-md-8 col-xs-12 order-md-1 order-xs-2 order-2">
              <div
                className="digma-image w-100"
                data-imageid="167BE8FB-4F27-4A85-AD28-A78B19EA662E"
              >
                <img
                  alt="Willow hill 9 holes"
                  className="w-100"
                  src="https://digmacustomers.blob.core.windows.net/4fe433c9-fb0f-47d4-9f59-37f1909ec6b8/80673549-97a1-4cf9-a4be-1b944ab29acc.webp"
                />
              </div>
            </div>
            <div className="col-md-4 col-xs-12 order-md-2 order-xs-1 order-1">
              <div className="mt-md-20 mt-5">
                <h3 className="Forms">Interactive Course Guide</h3>
                <p className="font-size-7 pr-xl-13">
                  Engaging imagery for your website. Create an Interactive
                  Course Guide to show your course and other facilities.
                </p>
              </div>
            </div>
          </div>
        </div>
        <Pricing />
        <div className="d-none d-md-block text-center pb-10 pt-5">
          <AniLink
            className="font-size-7"
            paintDrip
            hex="#2196f3"
            to="/pricing"
          >
            View full pricing details and plan comparison
          </AniLink>
        </div>
        <div style={{ overflow: 'hidden', background: '#f7f9fc' }}>
          <Commit />
        </div>
      </PageWrapper>
    </>
  );
};
export default PricingPage;
