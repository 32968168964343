import React from "react";

const Hero = ({ className, ...rest }) => {
  return (
    <div className={className} {...rest}>
    <div className="pt-4 pt-md-6 pt-lg-10 ">
      <div className="container">
        {/* Section Padding */}
        <div className="row justify-content-center">
          <div className="col-xl-12 col-lg-12 col-md-12">
            <div
              className="text-center mb-11 mb-lg-15"
              data-aos="zoom-in"
              data-aos-duration={500}
              data-aos-delay={500}
            >
              <h1 className="font-size-11 mb-5 Forms">Upgrade your game and experience</h1>
              <h2 className="font-size-7 mb-10">
              Allow customers to view a fly over of every hole of your golf course.
              </h2>
              <div className="digma" data-projectid="65B89E52-E4A9-4691-95C6-CC13D00596B6"></div>

            </div>
          </div>
        </div>
        {/* End Section Padding */}
      </div>
    </div>
    </div>
  );
};

export default Hero;
